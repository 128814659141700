@import "nprogress/nprogress.css";

#nprogress .bar {
  background: var(--palette-primary-main);
  height: 2px;
  z-index: 9999;
}

#nprogress .peg {
  box-shadow:
    0 0 10px var(--palette-secondary-main),
    0 0 5px var(--palette-secondary-main);
}
