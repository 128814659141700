/**
 * Normalize.css and sTailwind's base styles.
 */

@import-normalize;

@tailwind base;

:root {
  --font-base: var(--font-lato), "Roboto", '"Helvetica Neue"', "Arial", "sans-serif";
}

* {
  /* Text rendering */
  text-rendering: optimizeLegibility;
  -o-text-rendering: optimizeLegibility;
  -ms-text-rendering: optimizeLegibility;
  -moz-text-rendering: optimizeLegibility;
  -webkit-text-rendering: optimizeLegibility;
  -webkit-tap-highlight-color: transparent;
}

*,
::before,
::after {
  box-sizing: border-box;
}

* :focus {
  outline: none !important;
}

html {
  font-size: 62.5%;
  font-family: var(--font-base);
  scroll-behavior: smooth;
  scroll-padding-top: 100px;
}

html,
body {
  position: relative;
  padding: 0;
  margin: 0;
  min-height: 100vh;
  min-height: 100svh;
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

#__next {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  min-height: 100svh;
  overflow-x: clip;
}

main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  margin: 0;
}

h1,
.h1 {
  font: var(--font-h1);
}

h2,
.h2 {
  font: var(--font-h2);
}

h3,
.h3 {
  font: var(--font-h3);
}

h4,
.h4 {
  font: var(--font-h4);
}

h5,
.h5 {
  font: var(--font-h5);
}

h6,
.h6 {
  font: var(--font-h6);
}

p {
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

::marker {
  display: block;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

ul.marker-large li::marker {
  font-size: x-large;
}

/* Medium Devices, Desktops Only */
@media only screen and (min-width: 992px) {
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: rgba(0, 0, 0, 0);
  }

  ::-webkit-scrollbar:hover {
    width: 8px;
    height: 8px;
    background-color: rgba(0, 0, 0, 0.06);
  }

  ::-webkit-scrollbar-thumb {
    background-color: #bbb;
    border: 2px solid transparent;
    border-radius: 20px;
  }

  ::-webkit-scrollbar-thumb:active {
    background-color: #aaa;
    border-radius: 20px;
  }
}

@media (prefers-reduced-motion: reduce) {
  *,
  ::before,
  ::after {
    animation-delay: -1ms !important;
    animation-duration: 1ms !important;
    animation-iteration-count: 1 !important;
    background-attachment: initial !important;
    scroll-behavior: auto !important;
    transition-duration: 0s !important;
    transition-delay: 0s !important;
  }
}

@layer base {
  .container-inner {
    @apply md:px-28;
  }

  .transform-center {
    @apply left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform;
  }

  .flex-center {
    @apply flex items-center justify-center;
  }

  @supports not (text-wrap: pretty) {
    .text-pretty {
      text-wrap: balance;
    }
  }
}
