/**
 * Normalize.css and sTailwind's base styles.
 */

@import-normalize;

*, ::before, ::after{
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x:  ;
    --tw-pan-y:  ;
    --tw-pinch-zoom:  ;
    --tw-scroll-snap-strictness: proximity;
    --tw-gradient-from-position:  ;
    --tw-gradient-via-position:  ;
    --tw-gradient-to-position:  ;
    --tw-ordinal:  ;
    --tw-slashed-zero:  ;
    --tw-numeric-figure:  ;
    --tw-numeric-spacing:  ;
    --tw-numeric-fraction:  ;
    --tw-ring-inset:  ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur:  ;
    --tw-brightness:  ;
    --tw-contrast:  ;
    --tw-grayscale:  ;
    --tw-hue-rotate:  ;
    --tw-invert:  ;
    --tw-saturate:  ;
    --tw-sepia:  ;
    --tw-drop-shadow:  ;
    --tw-backdrop-blur:  ;
    --tw-backdrop-brightness:  ;
    --tw-backdrop-contrast:  ;
    --tw-backdrop-grayscale:  ;
    --tw-backdrop-hue-rotate:  ;
    --tw-backdrop-invert:  ;
    --tw-backdrop-opacity:  ;
    --tw-backdrop-saturate:  ;
    --tw-backdrop-sepia:  ;
    --tw-contain-size:  ;
    --tw-contain-layout:  ;
    --tw-contain-paint:  ;
    --tw-contain-style:  ;
}

::backdrop{
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x:  ;
    --tw-pan-y:  ;
    --tw-pinch-zoom:  ;
    --tw-scroll-snap-strictness: proximity;
    --tw-gradient-from-position:  ;
    --tw-gradient-via-position:  ;
    --tw-gradient-to-position:  ;
    --tw-ordinal:  ;
    --tw-slashed-zero:  ;
    --tw-numeric-figure:  ;
    --tw-numeric-spacing:  ;
    --tw-numeric-fraction:  ;
    --tw-ring-inset:  ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur:  ;
    --tw-brightness:  ;
    --tw-contrast:  ;
    --tw-grayscale:  ;
    --tw-hue-rotate:  ;
    --tw-invert:  ;
    --tw-saturate:  ;
    --tw-sepia:  ;
    --tw-drop-shadow:  ;
    --tw-backdrop-blur:  ;
    --tw-backdrop-brightness:  ;
    --tw-backdrop-contrast:  ;
    --tw-backdrop-grayscale:  ;
    --tw-backdrop-hue-rotate:  ;
    --tw-backdrop-invert:  ;
    --tw-backdrop-opacity:  ;
    --tw-backdrop-saturate:  ;
    --tw-backdrop-sepia:  ;
    --tw-contain-size:  ;
    --tw-contain-layout:  ;
    --tw-contain-paint:  ;
    --tw-contain-style:  ;
}

.transform-center{
    left: 50%;
    top: 50%;
    --tw-translate-x: -50%;
    --tw-translate-y: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.flex-center{
    display: flex;
    align-items: center;
    justify-content: center;
}

@supports not (text-wrap: pretty) {
    .text-pretty {
      text-wrap: balance;
    }
  }

:root {
  --font-base: var(--font-lato), "Roboto", '"Helvetica Neue"', "Arial", "sans-serif";
}

* {
  /* Text rendering */
  text-rendering: optimizeLegibility;
  -o-text-rendering: optimizeLegibility;
  -ms-text-rendering: optimizeLegibility;
  -moz-text-rendering: optimizeLegibility;
  -webkit-text-rendering: optimizeLegibility;
  -webkit-tap-highlight-color: transparent;
}

*,
::before,
::after {
  box-sizing: border-box;
}

* :focus {
  outline: none !important;
}

html {
  font-size: 62.5%;
  font-family: var(--font-base);
  scroll-behavior: smooth;
  scroll-padding-top: 100px;
}

html,
body {
  position: relative;
  padding: 0;
  margin: 0;
  min-height: 100vh;
  min-height: 100svh;
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

#__next {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  min-height: 100svh;
  overflow-x: clip;
}

main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  margin: 0;
}

h1,
.h1 {
  font: var(--font-h1);
}

h2,
.h2 {
  font: var(--font-h2);
}

h3,
.h3 {
  font: var(--font-h3);
}

h4,
.h4 {
  font: var(--font-h4);
}

h5,
.h5 {
  font: var(--font-h5);
}

h6,
.h6 {
  font: var(--font-h6);
}

p {
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

::marker {
  display: block;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

ul.marker-large li::marker {
  font-size: x-large;
}

/* Medium Devices, Desktops Only */
@media only screen and (min-width: 992px) {
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: rgba(0, 0, 0, 0);
  }

  ::-webkit-scrollbar:hover {
    width: 8px;
    height: 8px;
    background-color: rgba(0, 0, 0, 0.06);
  }

  ::-webkit-scrollbar-thumb {
    background-color: #bbb;
    border: 2px solid transparent;
    border-radius: 20px;
  }

  ::-webkit-scrollbar-thumb:active {
    background-color: #aaa;
    border-radius: 20px;
  }
}

@media (prefers-reduced-motion: reduce) {
  *,
  ::before,
  ::after {
    animation-delay: -1ms !important;
    animation-duration: 1ms !important;
    animation-iteration-count: 1 !important;
    background-attachment: initial !important;
    scroll-behavior: auto !important;
    transition-duration: 0s !important;
    transition-delay: 0s !important;
  }
}

@media (min-width: 640px){

  .xs\:flex-center{
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media (min-width: 768px){
  @media (min-width: 1024px){

        .sm\:container-inner{
            padding-left: 7rem;
            padding-right: 7rem;
        }
    }
}

/*----------------------------------------------------------------*/
/*  Print
/*----------------------------------------------------------------*/
@media all {
    /* Never show page breaks in normal view */
    .page-break-after,
    .page-break-before {
      display: none;
    }
  }
  
  @media print {
    /* html and body tweaks */
    html,
    body {
      height: auto !important;
      overflow: initial !important;
      background: none;
    }
  
    /* Page breaks */
    .page-break-after {
      display: block;
      page-break-after: always;
      position: relative;
    }
  
    .page-break-before {
      display: block;
      page-break-before: always;
      position: relative;
    }
  
    /* Hidden */
    nav,
    #nav-drawer,
    footer,
    .cky-btn-revisit-wrapper,
    .cky-consent-container {
      display: none !important;
    }
  }
  
/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #29d;

  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;

  width: 100%;
  height: 2px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #29d, 0 0 5px #29d;
  opacity: 1.0;
  transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;

  border: solid 2px transparent;
  border-top-color: #29d;
  border-left-color: #29d;
  border-radius: 50%;
  animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}
@keyframes nprogress-spinner {
  0%   { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


#nprogress .bar {
  background: var(--palette-primary-main);
  height: 2px;
  z-index: 9999;
}

#nprogress .peg {
  box-shadow:
    0 0 10px var(--palette-secondary-main),
    0 0 5px var(--palette-secondary-main);
}

/**
 * Component classes registered by plugins.
 */

.\!container{
  width: 100% !important;
  margin-right: auto !important;
  margin-left: auto !important;
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.container{
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 3rem;
  padding-left: 3rem;
}

@media (min-width: 640px){

  .\!container{
    max-width: 640px !important;
  }

  .container{
    max-width: 640px;
  }
}

@media (min-width: 768px){

  .\!container{
    max-width: 768px !important;
    padding-right: 3.6rem !important;
    padding-left: 3.6rem !important;
  }

  .container{
    max-width: 768px;
    padding-right: 3.6rem;
    padding-left: 3.6rem;
  }
}

@media (min-width: 1024px){

  .\!container{
    max-width: 1024px !important;
  }

  .container{
    max-width: 1024px;
  }
}

@media (min-width: 1280px){

  .\!container{
    max-width: 1280px !important;
  }

  .container{
    max-width: 1280px;
  }
}

@media (min-width: 1536px){

  .\!container{
    max-width: 1536px !important;
    padding-right: 12.8rem !important;
    padding-left: 12.8rem !important;
  }

  .container{
    max-width: 1536px;
    padding-right: 12.8rem;
    padding-left: 12.8rem;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

@media (min-width: 768px){

  .sm\:container{
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: 3rem;
    padding-left: 3rem;
  }

  @media (min-width: 640px){

    .sm\:container{
      max-width: 640px;
    }
  }

  @media (min-width: 768px){

    .sm\:container{
      max-width: 768px;
      padding-right: 3.6rem;
      padding-left: 3.6rem;
    }
  }

  @media (min-width: 1024px){

    .sm\:container{
      max-width: 1024px;
    }
  }

  @media (min-width: 1280px){

    .sm\:container{
      max-width: 1280px;
    }
  }

  @media (min-width: 1536px){

    .sm\:container{
      max-width: 1536px;
      padding-right: 12.8rem;
      padding-left: 12.8rem;
    }
  }
}

@media (min-width: 1536px){

  .xl\:\!container{
    width: 100% !important;
    margin-right: auto !important;
    margin-left: auto !important;
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  @media (min-width: 640px){

    .xl\:\!container{
      max-width: 640px !important;
    }
  }

  @media (min-width: 768px){

    .xl\:\!container{
      max-width: 768px !important;
      padding-right: 3.6rem !important;
      padding-left: 3.6rem !important;
    }
  }

  @media (min-width: 1024px){

    .xl\:\!container{
      max-width: 1024px !important;
    }
  }

  @media (min-width: 1280px){

    .xl\:\!container{
      max-width: 1280px !important;
    }
  }

  @media (min-width: 1536px){

    .xl\:\!container{
      max-width: 1536px !important;
      padding-right: 12.8rem !important;
      padding-left: 12.8rem !important;
    }
  }
}

/**
 * Tailwind's utility classes, generated based on your config file.
 */

.sr-only {

    position: absolute;

    width: 1px;

    height: 1px;

    padding: 0;

    margin: -1px;

    overflow: hidden;

    clip: rect(0, 0, 0, 0);

    white-space: nowrap;

    border-width: 0
}

.pointer-events-none {

    pointer-events: none
}

.pointer-events-auto {

    pointer-events: auto
}

.visible {

    visibility: visible
}

.invisible {

    visibility: hidden
}

.collapse {

    visibility: collapse
}

.fixed {

    position: fixed
}

.absolute {

    position: absolute
}

.relative {

    position: relative
}

.sticky {

    position: sticky
}

.inset-0 {

    inset: 0px
}

.inset-x-0 {

    left: 0px;

    right: 0px
}

.inset-x-8 {

    left: 2rem;

    right: 2rem
}

.inset-y-0 {

    top: 0px;

    bottom: 0px
}

.-bottom-3 {

    bottom: -0.75rem
}

.-right-8 {

    right: -2rem
}

.-top-56 {

    top: -14rem
}

.bottom-0 {

    bottom: 0px
}

.bottom-8 {

    bottom: 2rem
}

.left-0 {

    left: 0px
}

.left-4 {

    left: 1rem
}

.left-\[-50px\] {

    left: -50px
}

.left-\[0\] {

    left: 0
}

.right-0 {

    right: 0px
}

.right-1\.5 {

    right: 0.375rem
}

.right-3 {

    right: 0.75rem
}

.right-4 {

    right: 1rem
}

.top-0 {

    top: 0px
}

.top-1\.5 {

    top: 0.375rem
}

.top-1\/2 {

    top: 50%
}

.top-3 {

    top: 0.75rem
}

.top-\[10px\] {

    top: 10px
}

.top-\[5px\] {

    top: 5px
}

.top-auto {

    top: auto
}

.-z-10 {

    z-index: -10
}

.z-10 {

    z-index: 10
}

.z-\[2\] {

    z-index: 2
}

.z-\[3\] {

    z-index: 3
}

.z-\[4\] {

    z-index: 4
}

.z-\[5\] {

    z-index: 5
}

.order-last {

    order: 9999
}

.col-span-full {

    grid-column: 1 / -1
}

.\!m-0 {

    margin: 0px !important
}

.-m-1 {

    margin: -0.25rem
}

.-m-4 {

    margin: -1rem
}

.m-0 {

    margin: 0px
}

.m-1 {

    margin: 0.25rem
}

.m-1\.5 {

    margin: 0.375rem
}

.m-2 {

    margin: 0.5rem
}

.m-4 {

    margin: 1rem
}

.m-8 {

    margin: 2rem
}

.m-auto {

    margin: auto
}

.\!my-12 {

    margin-top: 3rem !important;

    margin-bottom: 3rem !important
}

.mx-0 {

    margin-left: 0px;

    margin-right: 0px
}

.mx-1\.5 {

    margin-left: 0.375rem;

    margin-right: 0.375rem
}

.mx-16 {

    margin-left: 4rem;

    margin-right: 4rem
}

.mx-2 {

    margin-left: 0.5rem;

    margin-right: 0.5rem
}

.mx-2\.5 {

    margin-left: 0.625rem;

    margin-right: 0.625rem
}

.mx-3\.5 {

    margin-left: 0.875rem;

    margin-right: 0.875rem
}

.mx-4 {

    margin-left: 1rem;

    margin-right: 1rem
}

.mx-8 {

    margin-left: 2rem;

    margin-right: 2rem
}

.mx-auto {

    margin-left: auto;

    margin-right: auto
}

.my-0 {

    margin-top: 0px;

    margin-bottom: 0px
}

.my-1\.5 {

    margin-top: 0.375rem;

    margin-bottom: 0.375rem
}

.my-12 {

    margin-top: 3rem;

    margin-bottom: 3rem
}

.my-14 {

    margin-top: 3.5rem;

    margin-bottom: 3.5rem
}

.my-2 {

    margin-top: 0.5rem;

    margin-bottom: 0.5rem
}

.my-32 {

    margin-top: 8rem;

    margin-bottom: 8rem
}

.my-4 {

    margin-top: 1rem;

    margin-bottom: 1rem
}

.my-6 {

    margin-top: 1.5rem;

    margin-bottom: 1.5rem
}

.my-8 {

    margin-top: 2rem;

    margin-bottom: 2rem
}

.\!mt-16 {

    margin-top: 4rem !important
}

.\!mt-2 {

    margin-top: 0.5rem !important
}

.\!mt-8 {

    margin-top: 2rem !important
}

.-mb-0\.5 {

    margin-bottom: -0.125rem
}

.-mb-2 {

    margin-bottom: -0.5rem
}

.-ml-1 {

    margin-left: -0.25rem
}

.-ml-12 {

    margin-left: -3rem
}

.-ml-2 {

    margin-left: -0.5rem
}

.-ml-20 {

    margin-left: -5rem
}

.-ml-8 {

    margin-left: -2rem
}

.-ml-\[1rem\] {

    margin-left: -1rem
}

.-mt-0\.5 {

    margin-top: -0.125rem
}

.-mt-10 {

    margin-top: -2.5rem
}

.-mt-12 {

    margin-top: -3rem
}

.-mt-20 {

    margin-top: -5rem
}

.-mt-44 {

    margin-top: -11rem
}

.-mt-5 {

    margin-top: -1.25rem
}

.-mt-6 {

    margin-top: -1.5rem
}

.mb-0\.5 {

    margin-bottom: 0.125rem
}

.mb-1 {

    margin-bottom: 0.25rem
}

.mb-10 {

    margin-bottom: 2.5rem
}

.mb-12 {

    margin-bottom: 3rem
}

.mb-14 {

    margin-bottom: 3.5rem
}

.mb-2 {

    margin-bottom: 0.5rem
}

.mb-3 {

    margin-bottom: 0.75rem
}

.mb-4 {

    margin-bottom: 1rem
}

.mb-5 {

    margin-bottom: 1.25rem
}

.mb-6 {

    margin-bottom: 1.5rem
}

.mb-8 {

    margin-bottom: 2rem
}

.mb-\[1\.6rem\] {

    margin-bottom: 1.6rem
}

.mb-auto {

    margin-bottom: auto
}

.ml-0 {

    margin-left: 0px
}

.ml-2\.5 {

    margin-left: 0.625rem
}

.ml-4 {

    margin-left: 1rem
}

.ml-auto {

    margin-left: auto
}

.mr-0\.5 {

    margin-right: 0.125rem
}

.mr-1 {

    margin-right: 0.25rem
}

.mr-12 {

    margin-right: 3rem
}

.mr-3 {

    margin-right: 0.75rem
}

.mr-4 {

    margin-right: 1rem
}

.mr-auto {

    margin-right: auto
}

.mt-1\.5 {

    margin-top: 0.375rem
}

.mt-10 {

    margin-top: 2.5rem
}

.mt-12 {

    margin-top: 3rem
}

.mt-16 {

    margin-top: 4rem
}

.mt-20 {

    margin-top: 5rem
}

.mt-3 {

    margin-top: 0.75rem
}

.mt-4 {

    margin-top: 1rem
}

.mt-6 {

    margin-top: 1.5rem
}

.mt-8 {

    margin-top: 2rem
}

.mt-\[2\.4rem\] {

    margin-top: 2.4rem
}

.mt-auto {

    margin-top: auto
}

.line-clamp-1 {

    overflow: hidden;

    display: -webkit-box;

    -webkit-box-orient: vertical;

    -webkit-line-clamp: 1
}

.line-clamp-2 {

    overflow: hidden;

    display: -webkit-box;

    -webkit-box-orient: vertical;

    -webkit-line-clamp: 2
}

.line-clamp-3 {

    overflow: hidden;

    display: -webkit-box;

    -webkit-box-orient: vertical;

    -webkit-line-clamp: 3
}

.line-clamp-4 {

    overflow: hidden;

    display: -webkit-box;

    -webkit-box-orient: vertical;

    -webkit-line-clamp: 4
}

.\!block {

    display: block !important
}

.block {

    display: block
}

.inline-block {

    display: inline-block
}

.flex {

    display: flex
}

.inline-flex {

    display: inline-flex
}

.grid {

    display: grid
}

.hidden {

    display: none
}

.aspect-square {

    aspect-ratio: 1 / 1
}

.aspect-video {

    aspect-ratio: 16 / 9
}

.\!h-auto {

    height: auto !important
}

.h-0 {

    height: 0px
}

.h-1\.5 {

    height: 0.375rem
}

.h-10 {

    height: 2.5rem
}

.h-12 {

    height: 3rem
}

.h-16 {

    height: 4rem
}

.h-2 {

    height: 0.5rem
}

.h-20 {

    height: 5rem
}

.h-24 {

    height: 6rem
}

.h-28 {

    height: 7rem
}

.h-3 {

    height: 0.75rem
}

.h-32 {

    height: 8rem
}

.h-5 {

    height: 1.25rem
}

.h-56 {

    height: 14rem
}

.h-64 {

    height: 16rem
}

.h-72 {

    height: 18rem
}

.h-8 {

    height: 2rem
}

.h-80 {

    height: 20rem
}

.h-96 {

    height: 24rem
}

.h-\[200px\] {

    height: 200px
}

.h-\[230px\] {

    height: 230px
}

.h-\[320px\] {

    height: 320px
}

.h-\[330px\] {

    height: 330px
}

.h-\[37px\] {

    height: 37px
}

.h-\[43px\] {

    height: 43px
}

.h-auto {

    height: auto
}

.h-fit {

    height: -moz-fit-content;

    height: fit-content
}

.h-full {

    height: 100%
}

.h-screen {

    height: 100vh
}

.max-h-24 {

    max-height: 6rem
}

.max-h-52 {

    max-height: 13rem
}

.max-h-56 {

    max-height: 14rem
}

.min-h-\[2\.05rem\] {

    min-height: 2.05rem
}

.min-h-\[2\.6rem\] {

    min-height: 2.6rem
}

.min-h-\[3rem\] {

    min-height: 3rem
}

.min-h-\[4\.05rem\] {

    min-height: 4.05rem
}

.min-h-\[4\.6rem\] {

    min-height: 4.6rem
}

.min-h-\[5rem\] {

    min-height: 5rem
}

.min-h-fit {

    min-height: -moz-fit-content;

    min-height: fit-content
}

.w-1\/3 {

    width: 33.333333%
}

.w-1\/4 {

    width: 25%
}

.w-10 {

    width: 2.5rem
}

.w-12 {

    width: 3rem
}

.w-20 {

    width: 5rem
}

.w-32 {

    width: 8rem
}

.w-36 {

    width: 9rem
}

.w-40 {

    width: 10rem
}

.w-56 {

    width: 14rem
}

.w-6 {

    width: 1.5rem
}

.w-7 {

    width: 1.75rem
}

.w-96 {

    width: 24rem
}

.w-\[19\.2rem\] {

    width: 19.2rem
}

.w-\[37px\] {

    width: 37px
}

.w-\[381px\] {

    width: 381px
}

.w-\[422px\] {

    width: 422px
}

.w-\[534px\] {

    width: 534px
}

.w-auto {

    width: auto
}

.w-fit {

    width: -moz-fit-content;

    width: fit-content
}

.w-full {

    width: 100%
}

.w-min {

    width: -moz-min-content;

    width: min-content
}

.\!min-w-0 {

    min-width: 0px !important
}

.min-w-24 {

    min-width: 6rem
}

.min-w-40 {

    min-width: 10rem
}

.min-w-fit {

    min-width: -moz-fit-content;

    min-width: fit-content
}

.min-w-min {

    min-width: -moz-min-content;

    min-width: min-content
}

.max-w-36 {

    max-width: 9rem
}

.max-w-48 {

    max-width: 12rem
}

.max-w-4xl {

    max-width: 56rem
}

.max-w-52 {

    max-width: 13rem
}

.max-w-56 {

    max-width: 14rem
}

.max-w-80 {

    max-width: 20rem
}

.max-w-\[1130px\] {

    max-width: 1130px
}

.max-w-\[300px\] {

    max-width: 300px
}

.max-w-\[320px\] {

    max-width: 320px
}

.max-w-\[330px\] {

    max-width: 330px
}

.max-w-\[332px\] {

    max-width: 332px
}

.max-w-\[350px\] {

    max-width: 350px
}

.max-w-\[360px\] {

    max-width: 360px
}

.max-w-\[421px\] {

    max-width: 421px
}

.max-w-\[540px\] {

    max-width: 540px
}

.max-w-\[75\.9rem\] {

    max-width: 75.9rem
}

.max-w-\[875px\] {

    max-width: 875px
}

.max-w-\[900px\] {

    max-width: 900px
}

.max-w-full {

    max-width: 100%
}

.max-w-max {

    max-width: -moz-max-content;

    max-width: max-content
}

.max-w-screen-md {

    max-width: 1024px
}

.max-w-screen-sm {

    max-width: 768px
}

.max-w-screen-xs {

    max-width: 640px
}

.flex-1 {

    flex: 1 1 0%
}

.shrink-0 {

    flex-shrink: 0
}

.grow {

    flex-grow: 1
}

.origin-bottom {

    transform-origin: bottom
}

.origin-top {

    transform-origin: top
}

.-translate-y-1\/2 {

    --tw-translate-y: -50%;

    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.-translate-y-2\.5 {

    --tw-translate-y: -0.625rem;

    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.translate-x-1\/2 {

    --tw-translate-x: 50%;

    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.\!rotate-\[144deg\] {

    --tw-rotate: 144deg !important;

    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important
}

.-rotate-180 {

    --tw-rotate: -180deg;

    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.rotate-180 {

    --tw-rotate: 180deg;

    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.rotate-90 {

    --tw-rotate: 90deg;

    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.scale-100 {

    --tw-scale-x: 1;

    --tw-scale-y: 1;

    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.transform {

    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

@keyframes spin {

    to {

        transform: rotate(360deg)
    }
}

.animate-spin {

    animation: spin 1s linear infinite
}

.cursor-default {

    cursor: default
}

.cursor-grab {

    cursor: grab
}

.cursor-not-allowed {

    cursor: not-allowed
}

.cursor-pointer {

    cursor: pointer
}

.cursor-zoom-in {

    cursor: zoom-in
}

.select-none {

    -webkit-user-select: none;

       -moz-user-select: none;

            user-select: none
}

.select-all {

    -webkit-user-select: all;

       -moz-user-select: all;

            user-select: all
}

.grid-cols-1 {

    grid-template-columns: repeat(1, minmax(0, 1fr))
}

.grid-cols-12 {

    grid-template-columns: repeat(12, minmax(0, 1fr))
}

.grid-cols-3 {

    grid-template-columns: repeat(3, minmax(0, 1fr))
}

.grid-cols-4 {

    grid-template-columns: repeat(4, minmax(0, 1fr))
}

.flex-row {

    flex-direction: row
}

.flex-col {

    flex-direction: column
}

.flex-wrap {

    flex-wrap: wrap
}

.flex-nowrap {

    flex-wrap: nowrap
}

.items-start {

    align-items: flex-start
}

.items-end {

    align-items: flex-end
}

.items-center {

    align-items: center
}

.items-baseline {

    align-items: baseline
}

.justify-start {

    justify-content: flex-start
}

.justify-end {

    justify-content: flex-end
}

.justify-center {

    justify-content: center
}

.justify-between {

    justify-content: space-between
}

.gap-1 {

    gap: 0.25rem
}

.gap-1\.5 {

    gap: 0.375rem
}

.gap-10 {

    gap: 2.5rem
}

.gap-12 {

    gap: 3rem
}

.gap-14 {

    gap: 3.5rem
}

.gap-16 {

    gap: 4rem
}

.gap-2 {

    gap: 0.5rem
}

.gap-24 {

    gap: 6rem
}

.gap-3 {

    gap: 0.75rem
}

.gap-3\.5 {

    gap: 0.875rem
}

.gap-4 {

    gap: 1rem
}

.gap-5 {

    gap: 1.25rem
}

.gap-6 {

    gap: 1.5rem
}

.gap-7 {

    gap: 1.75rem
}

.gap-8 {

    gap: 2rem
}

.gap-\[1\.6rem\] {

    gap: 1.6rem
}

.gap-\[16px\] {

    gap: 16px
}

.gap-\[3\.2rem\] {

    gap: 3.2rem
}

.gap-\[40px\] {

    gap: 40px
}

.gap-x-10 {

    -moz-column-gap: 2.5rem;

         column-gap: 2.5rem
}

.gap-x-12 {

    -moz-column-gap: 3rem;

         column-gap: 3rem
}

.gap-x-16 {

    -moz-column-gap: 4rem;

         column-gap: 4rem
}

.gap-x-20 {

    -moz-column-gap: 5rem;

         column-gap: 5rem
}

.gap-x-5 {

    -moz-column-gap: 1.25rem;

         column-gap: 1.25rem
}

.gap-x-6 {

    -moz-column-gap: 1.5rem;

         column-gap: 1.5rem
}

.gap-x-8 {

    -moz-column-gap: 2rem;

         column-gap: 2rem
}

.gap-y-12 {

    row-gap: 3rem
}

.gap-y-16 {

    row-gap: 4rem
}

.gap-y-3 {

    row-gap: 0.75rem
}

.gap-y-4 {

    row-gap: 1rem
}

.gap-y-6 {

    row-gap: 1.5rem
}

.gap-y-8 {

    row-gap: 2rem
}

.-space-y-2 > :not([hidden]) ~ :not([hidden]) {

    --tw-space-y-reverse: 0;

    margin-top: calc(-0.5rem * calc(1 - var(--tw-space-y-reverse)));

    margin-bottom: calc(-0.5rem * var(--tw-space-y-reverse))
}

.space-x-16 > :not([hidden]) ~ :not([hidden]) {

    --tw-space-x-reverse: 0;

    margin-right: calc(4rem * var(--tw-space-x-reverse));

    margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {

    --tw-space-x-reverse: 0;

    margin-right: calc(0.5rem * var(--tw-space-x-reverse));

    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-x-5 > :not([hidden]) ~ :not([hidden]) {

    --tw-space-x-reverse: 0;

    margin-right: calc(1.25rem * var(--tw-space-x-reverse));

    margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-x-8 > :not([hidden]) ~ :not([hidden]) {

    --tw-space-x-reverse: 0;

    margin-right: calc(2rem * var(--tw-space-x-reverse));

    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {

    --tw-space-y-reverse: 0;

    margin-top: calc(0.125rem * calc(1 - var(--tw-space-y-reverse)));

    margin-bottom: calc(0.125rem * var(--tw-space-y-reverse))
}

.space-y-1 > :not([hidden]) ~ :not([hidden]) {

    --tw-space-y-reverse: 0;

    margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));

    margin-bottom: calc(0.25rem * var(--tw-space-y-reverse))
}

.space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {

    --tw-space-y-reverse: 0;

    margin-top: calc(0.375rem * calc(1 - var(--tw-space-y-reverse)));

    margin-bottom: calc(0.375rem * var(--tw-space-y-reverse))
}

.space-y-10 > :not([hidden]) ~ :not([hidden]) {

    --tw-space-y-reverse: 0;

    margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse)));

    margin-bottom: calc(2.5rem * var(--tw-space-y-reverse))
}

.space-y-12 > :not([hidden]) ~ :not([hidden]) {

    --tw-space-y-reverse: 0;

    margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse)));

    margin-bottom: calc(3rem * var(--tw-space-y-reverse))
}

.space-y-14 > :not([hidden]) ~ :not([hidden]) {

    --tw-space-y-reverse: 0;

    margin-top: calc(3.5rem * calc(1 - var(--tw-space-y-reverse)));

    margin-bottom: calc(3.5rem * var(--tw-space-y-reverse))
}

.space-y-16 > :not([hidden]) ~ :not([hidden]) {

    --tw-space-y-reverse: 0;

    margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse)));

    margin-bottom: calc(4rem * var(--tw-space-y-reverse))
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {

    --tw-space-y-reverse: 0;

    margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));

    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse))
}

.space-y-3 > :not([hidden]) ~ :not([hidden]) {

    --tw-space-y-reverse: 0;

    margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));

    margin-bottom: calc(0.75rem * var(--tw-space-y-reverse))
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {

    --tw-space-y-reverse: 0;

    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));

    margin-bottom: calc(1rem * var(--tw-space-y-reverse))
}

.space-y-5 > :not([hidden]) ~ :not([hidden]) {

    --tw-space-y-reverse: 0;

    margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));

    margin-bottom: calc(1.25rem * var(--tw-space-y-reverse))
}

.space-y-6 > :not([hidden]) ~ :not([hidden]) {

    --tw-space-y-reverse: 0;

    margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));

    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse))
}

.space-y-8 > :not([hidden]) ~ :not([hidden]) {

    --tw-space-y-reverse: 0;

    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));

    margin-bottom: calc(2rem * var(--tw-space-y-reverse))
}

.space-y-\[1\.6rem\] > :not([hidden]) ~ :not([hidden]) {

    --tw-space-y-reverse: 0;

    margin-top: calc(1.6rem * calc(1 - var(--tw-space-y-reverse)));

    margin-bottom: calc(1.6rem * var(--tw-space-y-reverse))
}

.space-y-\[1\.85rem\] > :not([hidden]) ~ :not([hidden]) {

    --tw-space-y-reverse: 0;

    margin-top: calc(1.85rem * calc(1 - var(--tw-space-y-reverse)));

    margin-bottom: calc(1.85rem * var(--tw-space-y-reverse))
}

.space-y-\[16px\] > :not([hidden]) ~ :not([hidden]) {

    --tw-space-y-reverse: 0;

    margin-top: calc(16px * calc(1 - var(--tw-space-y-reverse)));

    margin-bottom: calc(16px * var(--tw-space-y-reverse))
}

.space-y-\[2\.4rem\] > :not([hidden]) ~ :not([hidden]) {

    --tw-space-y-reverse: 0;

    margin-top: calc(2.4rem * calc(1 - var(--tw-space-y-reverse)));

    margin-bottom: calc(2.4rem * var(--tw-space-y-reverse))
}

.space-y-\[3\.2rem\] > :not([hidden]) ~ :not([hidden]) {

    --tw-space-y-reverse: 0;

    margin-top: calc(3.2rem * calc(1 - var(--tw-space-y-reverse)));

    margin-bottom: calc(3.2rem * var(--tw-space-y-reverse))
}

.space-y-\[4rem\] > :not([hidden]) ~ :not([hidden]) {

    --tw-space-y-reverse: 0;

    margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse)));

    margin-bottom: calc(4rem * var(--tw-space-y-reverse))
}

.space-y-\[8px\] > :not([hidden]) ~ :not([hidden]) {

    --tw-space-y-reverse: 0;

    margin-top: calc(8px * calc(1 - var(--tw-space-y-reverse)));

    margin-bottom: calc(8px * var(--tw-space-y-reverse))
}

.self-start {

    align-self: flex-start
}

.self-end {

    align-self: flex-end
}

.self-stretch {

    align-self: stretch
}

.overflow-auto {

    overflow: auto
}

.overflow-hidden {

    overflow: hidden
}

.\!overflow-visible {

    overflow: visible !important
}

.overflow-visible {

    overflow: visible
}

.overflow-x-hidden {

    overflow-x: hidden
}

.truncate {

    overflow: hidden;

    text-overflow: ellipsis;

    white-space: nowrap
}

.text-ellipsis {

    text-overflow: ellipsis
}

.whitespace-nowrap {

    white-space: nowrap
}

.text-wrap {

    text-wrap: wrap
}

.text-nowrap {

    text-wrap: nowrap
}

.text-balance {

    text-wrap: balance
}

.text-pretty {

    text-wrap: pretty
}

.break-words {

    overflow-wrap: break-word
}

.\!rounded-none {

    border-radius: 0px !important
}

.rounded {

    border-radius: 0.25rem
}

.rounded-2xl {

    border-radius: 1rem
}

.rounded-3xl {

    border-radius: 1.5rem
}

.rounded-\[12px\] {

    border-radius: 12px
}

.rounded-full {

    border-radius: 9999px
}

.rounded-lg {

    border-radius: 0.5rem
}

.rounded-md {

    border-radius: 0.375rem
}

.rounded-xl {

    border-radius: 0.75rem
}

.border {

    border-width: 1px
}

.border-0 {

    border-width: 0px
}

.border-2 {

    border-width: 2px
}

.border-4 {

    border-width: 4px
}

.border-b {

    border-bottom-width: 1px
}

.border-b-2 {

    border-bottom-width: 2px
}

.border-r-2 {

    border-right-width: 2px
}

.border-t {

    border-top-width: 1px
}

.border-t-2 {

    border-top-width: 2px
}

.border-solid {

    border-style: solid
}

.border-dotted {

    border-style: dotted
}

.border-none {

    border-style: none
}

.border-background-default {

    --tw-border-opacity: 1;

    border-color: rgb(var(--palette-background-defaultChannel) / var(--tw-border-opacity, 1))
}

.border-primary-dark {

    --tw-border-opacity: 1;

    border-color: rgb(var(--palette-primary-darkChannel) / var(--tw-border-opacity, 1))
}

.border-primary-light {

    --tw-border-opacity: 1;

    border-color: rgb(var(--palette-primary-lightChannel) / var(--tw-border-opacity, 1))
}

.border-primary-main {

    --tw-border-opacity: 1;

    border-color: rgb(var(--palette-primary-mainChannel) / var(--tw-border-opacity, 1))
}

.border-red-500 {

    --tw-border-opacity: 1;

    border-color: rgb(239 68 68 / var(--tw-border-opacity, 1))
}

.\!bg-background-main {

    --tw-bg-opacity: 1 !important;

    background-color: rgb(var(--palette-background-mainChannel) / var(--tw-bg-opacity, 1)) !important
}

.\!bg-primary-main\/20 {

    background-color: rgb(var(--palette-primary-mainChannel) / 0.2) !important
}

.\!bg-transparent {

    background-color: transparent !important
}

.bg-background-default {

    --tw-bg-opacity: 1;

    background-color: rgb(var(--palette-background-defaultChannel) / var(--tw-bg-opacity, 1))
}

.bg-background-main {

    --tw-bg-opacity: 1;

    background-color: rgb(var(--palette-background-mainChannel) / var(--tw-bg-opacity, 1))
}

.bg-background-paper {

    --tw-bg-opacity: 1;

    background-color: rgb(var(--palette-background-paperChannel) / var(--tw-bg-opacity, 1))
}

.bg-black {

    --tw-bg-opacity: 1;

    background-color: rgb(0 0 0 / var(--tw-bg-opacity, 1))
}

.bg-black\/30 {

    background-color: rgb(0 0 0 / 0.3)
}

.bg-black\/40 {

    background-color: rgb(0 0 0 / 0.4)
}

.bg-black\/50 {

    background-color: rgb(0 0 0 / 0.5)
}

.bg-black\/60 {

    background-color: rgb(0 0 0 / 0.6)
}

.bg-primary-dark {

    --tw-bg-opacity: 1;

    background-color: rgb(var(--palette-primary-darkChannel) / var(--tw-bg-opacity, 1))
}

.bg-primary-light {

    --tw-bg-opacity: 1;

    background-color: rgb(var(--palette-primary-lightChannel) / var(--tw-bg-opacity, 1))
}

.bg-primary-light\/20 {

    background-color: rgb(var(--palette-primary-lightChannel) / 0.2)
}

.bg-primary-main {

    --tw-bg-opacity: 1;

    background-color: rgb(var(--palette-primary-mainChannel) / var(--tw-bg-opacity, 1))
}

.bg-red-300\/20 {

    background-color: rgb(252 165 165 / 0.2)
}

.bg-secondary-main {

    --tw-bg-opacity: 1;

    background-color: rgb(var(--palette-secondary-mainChannel) / var(--tw-bg-opacity, 1))
}

.bg-text-dark {

    --tw-bg-opacity: 1;

    background-color: rgb(var(--palette-text-secondaryChannel) / var(--tw-bg-opacity, 1))
}

.bg-text-disabled {

    background-color: var(--palette-text-disabled)
}

.bg-text-light {

    --tw-bg-opacity: 1;

    background-color: rgb(var(--palette-text-primaryChannel) / var(--tw-bg-opacity, 1))
}

.bg-transparent {

    background-color: transparent
}

.bg-white {

    --tw-bg-opacity: 1;

    background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1))
}

.bg-white\/20 {

    background-color: rgb(255 255 255 / 0.2)
}

.bg-white\/40 {

    background-color: rgb(255 255 255 / 0.4)
}

.bg-white\/5 {

    background-color: rgb(255 255 255 / 0.05)
}

.bg-white\/50 {

    background-color: rgb(255 255 255 / 0.5)
}

.stroke-text-dark {

    stroke: rgb(var(--palette-text-secondaryChannel) / 1)
}

.object-contain {

    -o-object-fit: contain;

       object-fit: contain
}

.object-cover {

    -o-object-fit: cover;

       object-fit: cover
}

.\!p-0 {

    padding: 0px !important
}

.\!p-10 {

    padding: 2.5rem !important
}

.p-0 {

    padding: 0px
}

.p-0\.5 {

    padding: 0.125rem
}

.p-1 {

    padding: 0.25rem
}

.p-1\.5 {

    padding: 0.375rem
}

.p-10 {

    padding: 2.5rem
}

.p-12 {

    padding: 3rem
}

.p-14 {

    padding: 3.5rem
}

.p-2 {

    padding: 0.5rem
}

.p-2\.5 {

    padding: 0.625rem
}

.p-3 {

    padding: 0.75rem
}

.p-4 {

    padding: 1rem
}

.p-6 {

    padding: 1.5rem
}

.p-7 {

    padding: 1.75rem
}

.p-8 {

    padding: 2rem
}

.p-\[0\.3px\] {

    padding: 0.3px
}

.p-\[2\.4rem\] {

    padding: 2.4rem
}

.\!py-0 {

    padding-top: 0px !important;

    padding-bottom: 0px !important
}

.\!py-1\.5 {

    padding-top: 0.375rem !important;

    padding-bottom: 0.375rem !important
}

.\!py-12 {

    padding-top: 3rem !important;

    padding-bottom: 3rem !important
}

.\!py-2 {

    padding-top: 0.5rem !important;

    padding-bottom: 0.5rem !important
}

.px-0 {

    padding-left: 0px;

    padding-right: 0px
}

.px-16 {

    padding-left: 4rem;

    padding-right: 4rem
}

.px-2 {

    padding-left: 0.5rem;

    padding-right: 0.5rem
}

.px-3 {

    padding-left: 0.75rem;

    padding-right: 0.75rem
}

.px-4 {

    padding-left: 1rem;

    padding-right: 1rem
}

.px-5 {

    padding-left: 1.25rem;

    padding-right: 1.25rem
}

.px-6 {

    padding-left: 1.5rem;

    padding-right: 1.5rem
}

.px-8 {

    padding-left: 2rem;

    padding-right: 2rem
}

.py-0 {

    padding-top: 0px;

    padding-bottom: 0px
}

.py-0\.5 {

    padding-top: 0.125rem;

    padding-bottom: 0.125rem
}

.py-1 {

    padding-top: 0.25rem;

    padding-bottom: 0.25rem
}

.py-12 {

    padding-top: 3rem;

    padding-bottom: 3rem
}

.py-16 {

    padding-top: 4rem;

    padding-bottom: 4rem
}

.py-2 {

    padding-top: 0.5rem;

    padding-bottom: 0.5rem
}

.py-2\.5 {

    padding-top: 0.625rem;

    padding-bottom: 0.625rem
}

.py-24 {

    padding-top: 6rem;

    padding-bottom: 6rem
}

.py-3 {

    padding-top: 0.75rem;

    padding-bottom: 0.75rem
}

.py-3\.5 {

    padding-top: 0.875rem;

    padding-bottom: 0.875rem
}

.py-4 {

    padding-top: 1rem;

    padding-bottom: 1rem
}

.py-5 {

    padding-top: 1.25rem;

    padding-bottom: 1.25rem
}

.py-6 {

    padding-top: 1.5rem;

    padding-bottom: 1.5rem
}

.py-8 {

    padding-top: 2rem;

    padding-bottom: 2rem
}

.py-\[1\.1rem\] {

    padding-top: 1.1rem;

    padding-bottom: 1.1rem
}

.py-\[2\.4rem\] {

    padding-top: 2.4rem;

    padding-bottom: 2.4rem
}

.py-\[5\.6rem\] {

    padding-top: 5.6rem;

    padding-bottom: 5.6rem
}

.\!pb-0 {

    padding-bottom: 0px !important
}

.\!pb-16 {

    padding-bottom: 4rem !important
}

.\!pt-0 {

    padding-top: 0px !important
}

.pb-16 {

    padding-bottom: 4rem
}

.pb-20 {

    padding-bottom: 5rem
}

.pb-3 {

    padding-bottom: 0.75rem
}

.pb-32 {

    padding-bottom: 8rem
}

.pb-4 {

    padding-bottom: 1rem
}

.pb-44 {

    padding-bottom: 11rem
}

.pb-8 {

    padding-bottom: 2rem
}

.pl-1 {

    padding-left: 0.25rem
}

.pr-4 {

    padding-right: 1rem
}

.pt-0 {

    padding-top: 0px
}

.pt-1 {

    padding-top: 0.25rem
}

.pt-10 {

    padding-top: 2.5rem
}

.pt-14 {

    padding-top: 3.5rem
}

.pt-2 {

    padding-top: 0.5rem
}

.pt-3 {

    padding-top: 0.75rem
}

.pt-4 {

    padding-top: 1rem
}

.pt-8 {

    padding-top: 2rem
}

.text-left {

    text-align: left
}

.text-center {

    text-align: center
}

.text-start {

    text-align: start
}

.font-sans {

    font-family: var(--font-base), -apple-system, BlinkMacSystemFont, "Segoe UI", "Noto Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"
}

.text-2xl {

    font-size: 1.5rem;

    line-height: 2rem
}

.text-3xl {

    font-size: 1.875rem;

    line-height: 2.25rem
}

.text-4xl {

    font-size: 2.25rem;

    line-height: 2.5rem
}

.text-5xl {

    font-size: 3rem;

    line-height: 1
}

.text-7xl {

    font-size: 4.5rem;

    line-height: 1
}

.text-8xl {

    font-size: 6rem;

    line-height: 1
}

.text-9xl {

    font-size: 8rem;

    line-height: 1
}

.text-\[0px\] {

    font-size: 0px
}

.text-\[12px\] {

    font-size: 12px
}

.text-\[14px\] {

    font-size: 14px
}

.text-\[18px\] {

    font-size: 18px
}

.text-\[20px\] {

    font-size: 20px
}

.text-\[28px\] {

    font-size: 28px
}

.text-\[3\.2rem\] {

    font-size: 3.2rem
}

.text-\[36px\] {

    font-size: 36px
}

.text-\[4rem\] {

    font-size: 4rem
}

.text-lg {

    font-size: 1.125rem;

    line-height: 1.75rem
}

.text-xl {

    font-size: 1.25rem;

    line-height: 1.75rem
}

.\!font-bold {

    font-weight: 700 !important
}

.font-black {

    font-weight: 900
}

.font-bold {

    font-weight: 700
}

.font-medium {

    font-weight: 500
}

.font-normal {

    font-weight: 400
}

.font-semibold {

    font-weight: 600
}

.uppercase {

    text-transform: uppercase
}

.capitalize {

    text-transform: capitalize
}

.normal-case {

    text-transform: none
}

.leading-4 {

    line-height: 1rem
}

.leading-\[26px\] {

    line-height: 26px
}

.leading-\[28px\] {

    line-height: 28px
}

.leading-none {

    line-height: 1
}

.leading-tight {

    line-height: 1.25
}

.tracking-tight {

    letter-spacing: -0.025em
}

.\!text-dark {

    color: rgba(var(--palette-text-secondaryChannel)) !important
}

.\!text-disabled {

    color: var(--palette-text-disabled) !important
}

.\!text-light {

    color: rgba(var(--palette-text-primaryChannel)) !important
}

.text-\[\#02010d\] {

    --tw-text-opacity: 1;

    color: rgb(2 1 13 / var(--tw-text-opacity, 1))
}

.text-accent2-main {

    --tw-text-opacity: 1;

    color: rgb(var(--palette-accent2-mainChannel) / var(--tw-text-opacity, 1))
}

.text-background-paper {

    --tw-text-opacity: 1;

    color: rgb(var(--palette-background-paperChannel) / var(--tw-text-opacity, 1))
}

.text-black {

    --tw-text-opacity: 1;

    color: rgb(0 0 0 / var(--tw-text-opacity, 1))
}

.text-dark {

    color: rgba(var(--palette-text-secondaryChannel))
}

.text-disabled {

    color: var(--palette-text-disabled)
}

.text-gray-600 {

    --tw-text-opacity: 1;

    color: rgb(75 85 99 / var(--tw-text-opacity, 1))
}

.text-inherit {

    color: inherit
}

.text-light {

    color: rgba(var(--palette-text-primaryChannel))
}

.text-light\/80 {

    color: rgba(var(--palette-text-primaryChannel), 0.8)
}

.text-primary-dark {

    --tw-text-opacity: 1;

    color: rgb(var(--palette-primary-darkChannel) / var(--tw-text-opacity, 1))
}

.text-primary-main {

    --tw-text-opacity: 1;

    color: rgb(var(--palette-primary-mainChannel) / var(--tw-text-opacity, 1))
}

.text-red-500 {

    --tw-text-opacity: 1;

    color: rgb(239 68 68 / var(--tw-text-opacity, 1))
}

.text-secondary-main {

    --tw-text-opacity: 1;

    color: rgb(var(--palette-secondary-mainChannel) / var(--tw-text-opacity, 1))
}

.text-text-dark\/80 {

    color: rgb(var(--palette-text-secondaryChannel) / 0.8)
}

.text-text-light {

    --tw-text-opacity: 1;

    color: rgb(var(--palette-text-primaryChannel) / var(--tw-text-opacity, 1))
}

.text-text-light\/70 {

    color: rgb(var(--palette-text-primaryChannel) / 0.7)
}

.text-white {

    --tw-text-opacity: 1;

    color: rgb(255 255 255 / var(--tw-text-opacity, 1))
}

.underline {

    text-decoration-line: underline
}

.decoration-dotted {

    text-decoration-style: dotted
}

.caret-transparent {

    caret-color: transparent
}

.opacity-0 {

    opacity: 0
}

.opacity-100 {

    opacity: 1
}

.opacity-25 {

    opacity: 0.25
}

.opacity-75 {

    opacity: 0.75
}

.\!shadow-inner {

    --tw-shadow: inset 0 2px 4px 0 rgb(0 0 0 / 0.05) !important;

    --tw-shadow-colored: inset 0 2px 4px 0 var(--tw-shadow-color) !important;

    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important
}

.\!shadow-none {

    --tw-shadow: 0 0 #0000 !important;

    --tw-shadow-colored: 0 0 #0000 !important;

    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important
}

.shadow {

    --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);

    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);

    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-lg {

    --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);

    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);

    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-md {

    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);

    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);

    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-none {

    --tw-shadow: 0 0 #0000;

    --tw-shadow-colored: 0 0 #0000;

    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-sm {

    --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);

    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);

    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.outline {

    outline-style: solid
}

.ring {

    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);

    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);

    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.ring-2 {

    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);

    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);

    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.ring-primary-main {

    --tw-ring-opacity: 1;

    --tw-ring-color: rgb(var(--palette-primary-mainChannel) / var(--tw-ring-opacity, 1))
}

.blur {

    --tw-blur: blur(8px);

    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.drop-shadow-md {

    --tw-drop-shadow: drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06));

    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.drop-shadow-sm {

    --tw-drop-shadow: drop-shadow(0 1px 1px rgb(0 0 0 / 0.05));

    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.filter {

    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.backdrop-blur {

    --tw-backdrop-blur: blur(8px);

    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);

    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia)
}

.backdrop-blur-2xl {

    --tw-backdrop-blur: blur(40px);

    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);

    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia)
}

.backdrop-blur-sm {

    --tw-backdrop-blur: blur(4px);

    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);

    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia)
}

.backdrop-blur-xl {

    --tw-backdrop-blur: blur(24px);

    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);

    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia)
}

.\!transition-all {

    transition-property: all !important;

    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;

    transition-duration: 150ms !important
}

.transition {

    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;

    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;

    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;

    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

    transition-duration: 150ms
}

.transition-all {

    transition-property: all;

    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

    transition-duration: 150ms
}

.transition-colors {

    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;

    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

    transition-duration: 150ms
}

.transition-none {

    transition-property: none
}

.transition-transform {

    transition-property: transform;

    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

    transition-duration: 150ms
}

.delay-150 {

    transition-delay: 150ms
}

.\!duration-700 {

    transition-duration: 700ms !important
}

.duration-150 {

    transition-duration: 150ms
}

.duration-200 {

    transition-duration: 200ms
}

.duration-300 {

    transition-duration: 300ms
}

.duration-500 {

    transition-duration: 500ms
}

.duration-700 {

    transition-duration: 700ms
}

.before\:hidden::before {

    content: var(--tw-content);

    display: none
}

.hover\:gap-3\.5:hover {

    gap: 0.875rem
}

.hover\:gap-5:hover {

    gap: 1.25rem
}

.hover\:border-2:hover {

    border-width: 2px
}

.hover\:bg-background-paper\/60:hover {

    background-color: rgb(var(--palette-background-paperChannel) / 0.6)
}

.hover\:bg-black\/40:hover {

    background-color: rgb(0 0 0 / 0.4)
}

.hover\:bg-black\/60:hover {

    background-color: rgb(0 0 0 / 0.6)
}

.hover\:bg-primary-light:hover {

    --tw-bg-opacity: 1;

    background-color: rgb(var(--palette-primary-lightChannel) / var(--tw-bg-opacity, 1))
}

.hover\:bg-primary-main:hover {

    --tw-bg-opacity: 1;

    background-color: rgb(var(--palette-primary-mainChannel) / var(--tw-bg-opacity, 1))
}

.hover\:bg-white\/60:hover {

    background-color: rgb(255 255 255 / 0.6)
}

.hover\:text-dark:hover {

    color: rgba(var(--palette-text-secondaryChannel))
}

.hover\:text-primary-main:hover {

    --tw-text-opacity: 1;

    color: rgb(var(--palette-primary-mainChannel) / var(--tw-text-opacity, 1))
}

.hover\:shadow-xl:hover {

    --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);

    --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);

    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.hover\:ring-\[2\.5px\]:hover {

    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);

    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2.5px + var(--tw-ring-offset-width)) var(--tw-ring-color);

    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.hover\:ring-primary-main:hover {

    --tw-ring-opacity: 1;

    --tw-ring-color: rgb(var(--palette-primary-mainChannel) / var(--tw-ring-opacity, 1))
}

.disabled\:bg-primary-light\/30:disabled {

    background-color: rgb(var(--palette-primary-lightChannel) / 0.3)
}

.disabled\:text-disabled:disabled {

    color: var(--palette-text-disabled)
}

.group:hover .group-hover\:mt-0 {

    margin-top: 0px
}

.group:hover .group-hover\:translate-x-2 {

    --tw-translate-x: 0.5rem;

    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.group:hover .group-hover\:scale-50 {

    --tw-scale-x: .5;

    --tw-scale-y: .5;

    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.group:hover .group-hover\:opacity-0 {

    opacity: 0
}

.group:hover .group-hover\:opacity-100 {

    opacity: 1
}

.group:hover .group-hover\:delay-0 {

    transition-delay: 0s
}

@media (min-width: 640px) {

    .xs\:absolute {

        position: absolute
    }

    .xs\:order-first {

        order: -9999
    }

    .xs\:m-8 {

        margin: 2rem
    }

    .xs\:mx-auto {

        margin-left: auto;

        margin-right: auto
    }

    .xs\:mb-0 {

        margin-bottom: 0px
    }

    .xs\:mt-0 {

        margin-top: 0px
    }

    .xs\:block {

        display: block
    }

    .xs\:flex {

        display: flex
    }

    .xs\:grid {

        display: grid
    }

    .xs\:hidden {

        display: none
    }

    .xs\:aspect-square {

        aspect-ratio: 1 / 1
    }

    .xs\:aspect-video {

        aspect-ratio: 16 / 9
    }

    .xs\:h-10 {

        height: 2.5rem
    }

    .xs\:h-\[360px\] {

        height: 360px
    }

    .xs\:h-auto {

        height: auto
    }

    .xs\:max-h-\[258px\] {

        max-height: 258px
    }

    .xs\:w-96 {

        width: 24rem
    }

    .xs\:w-fit {

        width: -moz-fit-content;

        width: fit-content
    }

    .xs\:w-full {

        width: 100%
    }

    .xs\:max-w-\[400px\] {

        max-width: 400px
    }

    .xs\:max-w-full {

        max-width: 100%
    }

    .xs\:basis-0 {

        flex-basis: 0px
    }

    .xs\:grid-cols-2 {

        grid-template-columns: repeat(2, minmax(0, 1fr))
    }

    .xs\:flex-row {

        flex-direction: row
    }

    .xs\:flex-col {

        flex-direction: column
    }

    .xs\:flex-wrap {

        flex-wrap: wrap
    }

    .xs\:items-center {

        align-items: center
    }

    .xs\:gap-12 {

        gap: 3rem
    }

    .xs\:gap-24 {

        gap: 6rem
    }

    .xs\:gap-\[3\.2rem\] {

        gap: 3.2rem
    }

    .xs\:gap-x-16 {

        -moz-column-gap: 4rem;

             column-gap: 4rem
    }

    .xs\:space-y-0 > :not([hidden]) ~ :not([hidden]) {

        --tw-space-y-reverse: 0;

        margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));

        margin-bottom: calc(0px * var(--tw-space-y-reverse))
    }

    .xs\:space-y-6 > :not([hidden]) ~ :not([hidden]) {

        --tw-space-y-reverse: 0;

        margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));

        margin-bottom: calc(1.5rem * var(--tw-space-y-reverse))
    }

    .xs\:space-y-8 > :not([hidden]) ~ :not([hidden]) {

        --tw-space-y-reverse: 0;

        margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));

        margin-bottom: calc(2rem * var(--tw-space-y-reverse))
    }

    .xs\:rounded-none {

        border-radius: 0px
    }

    .xs\:bg-background-default {

        --tw-bg-opacity: 1;

        background-color: rgb(var(--palette-background-defaultChannel) / var(--tw-bg-opacity, 1))
    }

    .xs\:bg-background-main {

        --tw-bg-opacity: 1;

        background-color: rgb(var(--palette-background-mainChannel) / var(--tw-bg-opacity, 1))
    }

    .xs\:p-0 {

        padding: 0px
    }

    .xs\:p-10 {

        padding: 2.5rem
    }

    .xs\:px-16 {

        padding-left: 4rem;

        padding-right: 4rem
    }

    .xs\:px-24 {

        padding-left: 6rem;

        padding-right: 6rem
    }

    .xs\:px-6 {

        padding-left: 1.5rem;

        padding-right: 1.5rem
    }

    .xs\:px-8 {

        padding-left: 2rem;

        padding-right: 2rem
    }

    .xs\:py-8 {

        padding-top: 2rem;

        padding-bottom: 2rem
    }

    .xs\:pb-24 {

        padding-bottom: 6rem
    }

    .xs\:text-center {

        text-align: center
    }

    .xs\:text-2xl {

        font-size: 1.5rem;

        line-height: 2rem
    }

    .xs\:text-9xl {

        font-size: 8rem;

        line-height: 1
    }

    .xs\:shadow {

        --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);

        --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);

        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
    }
}

@media (min-width: 768px) {

    .sm\:absolute {

        position: absolute
    }

    .sm\:sticky {

        position: sticky
    }

    .sm\:bottom-0 {

        bottom: 0px
    }

    .sm\:left-20 {

        left: 5rem
    }

    .sm\:top-0 {

        top: 0px
    }

    .sm\:top-\[11\.2rem\] {

        top: 11.2rem
    }

    .sm\:order-first {

        order: -9999
    }

    .sm\:col-span-4 {

        grid-column: span 4 / span 4
    }

    .sm\:col-span-6 {

        grid-column: span 6 / span 6
    }

    .sm\:col-span-8 {

        grid-column: span 8 / span 8
    }

    .sm\:col-span-full {

        grid-column: 1 / -1
    }

    .sm\:m-8 {

        margin: 2rem
    }

    .sm\:my-0 {

        margin-top: 0px;

        margin-bottom: 0px
    }

    .sm\:my-20 {

        margin-top: 5rem;

        margin-bottom: 5rem
    }

    .sm\:my-24 {

        margin-top: 6rem;

        margin-bottom: 6rem
    }

    .sm\:-ml-\[3\.2rem\] {

        margin-left: -3.2rem
    }

    .sm\:-mt-52 {

        margin-top: -13rem
    }

    .sm\:mb-12 {

        margin-bottom: 3rem
    }

    .sm\:mb-16 {

        margin-bottom: 4rem
    }

    .sm\:mb-4 {

        margin-bottom: 1rem
    }

    .sm\:ml-auto {

        margin-left: auto
    }

    .sm\:mt-0 {

        margin-top: 0px
    }

    .sm\:mt-16 {

        margin-top: 4rem
    }

    .sm\:mt-20 {

        margin-top: 5rem
    }

    .sm\:mt-8 {

        margin-top: 2rem
    }

    .sm\:block {

        display: block
    }

    .sm\:flex {

        display: flex
    }

    .sm\:grid {

        display: grid
    }

    .sm\:hidden {

        display: none
    }

    .sm\:h-0 {

        height: 0px
    }

    .sm\:h-auto {

        height: auto
    }

    .sm\:min-h-fit {

        min-height: -moz-fit-content;

        min-height: fit-content
    }

    .sm\:w-1\/2 {

        width: 50%
    }

    .sm\:w-1\/3 {

        width: 33.333333%
    }

    .sm\:w-2\/3 {

        width: 66.666667%
    }

    .sm\:w-2\/5 {

        width: 40%
    }

    .sm\:w-60 {

        width: 15rem
    }

    .sm\:w-auto {

        width: auto
    }

    .sm\:w-fit {

        width: -moz-fit-content;

        width: fit-content
    }

    .sm\:min-w-\[460px\] {

        min-width: 460px
    }

    .sm\:max-w-2xl {

        max-width: 42rem
    }

    .sm\:max-w-\[96\%\] {

        max-width: 96%
    }

    .sm\:max-w-screen-xs {

        max-width: 640px
    }

    .sm\:flex-none {

        flex: none
    }

    .sm\:grid-cols-2 {

        grid-template-columns: repeat(2, minmax(0, 1fr))
    }

    .sm\:grid-cols-4 {

        grid-template-columns: repeat(4, minmax(0, 1fr))
    }

    .sm\:flex-row {

        flex-direction: row
    }

    .sm\:flex-col {

        flex-direction: column
    }

    .sm\:items-center {

        align-items: center
    }

    .sm\:justify-normal {

        justify-content: normal
    }

    .sm\:gap-0 {

        gap: 0px
    }

    .sm\:gap-10 {

        gap: 2.5rem
    }

    .sm\:gap-12 {

        gap: 3rem
    }

    .sm\:gap-16 {

        gap: 4rem
    }

    .sm\:gap-8 {

        gap: 2rem
    }

    .sm\:gap-x-10 {

        -moz-column-gap: 2.5rem;

             column-gap: 2.5rem
    }

    .sm\:gap-x-16 {

        -moz-column-gap: 4rem;

             column-gap: 4rem
    }

    .sm\:space-y-0 > :not([hidden]) ~ :not([hidden]) {

        --tw-space-y-reverse: 0;

        margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));

        margin-bottom: calc(0px * var(--tw-space-y-reverse))
    }

    .sm\:space-y-20 > :not([hidden]) ~ :not([hidden]) {

        --tw-space-y-reverse: 0;

        margin-top: calc(5rem * calc(1 - var(--tw-space-y-reverse)));

        margin-bottom: calc(5rem * var(--tw-space-y-reverse))
    }

    .sm\:space-y-\[64px\] > :not([hidden]) ~ :not([hidden]) {

        --tw-space-y-reverse: 0;

        margin-top: calc(64px * calc(1 - var(--tw-space-y-reverse)));

        margin-bottom: calc(64px * var(--tw-space-y-reverse))
    }

    .sm\:rounded-xl {

        border-radius: 0.75rem
    }

    .sm\:rounded-l-none {

        border-top-left-radius: 0px;

        border-bottom-left-radius: 0px
    }

    .sm\:border {

        border-width: 1px
    }

    .sm\:border-solid {

        border-style: solid
    }

    .sm\:border-primary-light {

        --tw-border-opacity: 1;

        border-color: rgb(var(--palette-primary-lightChannel) / var(--tw-border-opacity, 1))
    }

    .sm\:p-16 {

        padding: 4rem
    }

    .sm\:p-20 {

        padding: 5rem
    }

    .sm\:p-6 {

        padding: 1.5rem
    }

    .sm\:\!py-20 {

        padding-top: 5rem !important;

        padding-bottom: 5rem !important
    }

    .sm\:px-0 {

        padding-left: 0px;

        padding-right: 0px
    }

    .sm\:px-24 {

        padding-left: 6rem;

        padding-right: 6rem
    }

    .sm\:px-36 {

        padding-left: 9rem;

        padding-right: 9rem
    }

    .sm\:px-44 {

        padding-left: 11rem;

        padding-right: 11rem
    }

    .sm\:py-0 {

        padding-top: 0px;

        padding-bottom: 0px
    }

    .sm\:py-2 {

        padding-top: 0.5rem;

        padding-bottom: 0.5rem
    }

    .sm\:py-8 {

        padding-top: 2rem;

        padding-bottom: 2rem
    }

    .sm\:py-\[6\.4rem\] {

        padding-top: 6.4rem;

        padding-bottom: 6.4rem
    }

    .sm\:\!pb-20 {

        padding-bottom: 5rem !important
    }

    .sm\:pb-24 {

        padding-bottom: 6rem
    }

    .sm\:pb-48 {

        padding-bottom: 12rem
    }

    .sm\:pb-8 {

        padding-bottom: 2rem
    }

    .sm\:pt-\[5\.6rem\] {

        padding-top: 5.6rem
    }

    .sm\:text-center {

        text-align: center
    }

    .sm\:text-\[10\.2rem\] {

        font-size: 10.2rem
    }

    .sm\:text-\[20px\] {

        font-size: 20px
    }

    .group:hover .sm\:group-hover\:bg-background-paper {

        --tw-bg-opacity: 1;

        background-color: rgb(var(--palette-background-paperChannel) / var(--tw-bg-opacity, 1))
    }

    .group:hover .sm\:group-hover\:shadow {

        --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);

        --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);

        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
    }
}

@media (min-width: 1024px) {

    .md\:visible {

        visibility: visible
    }

    .md\:my-0 {

        margin-top: 0px;

        margin-bottom: 0px
    }

    .md\:my-4 {

        margin-top: 1rem;

        margin-bottom: 1rem
    }

    .md\:-mt-96 {

        margin-top: -24rem
    }

    .md\:flex {

        display: flex
    }

    .md\:hidden {

        display: none
    }

    .md\:h-14 {

        height: 3.5rem
    }

    .md\:h-\[527px\] {

        height: 527px
    }

    .md\:w-2\/5 {

        width: 40%
    }

    .md\:w-3\/5 {

        width: 60%
    }

    .md\:w-52 {

        width: 13rem
    }

    .md\:w-\[30\%\] {

        width: 30%
    }

    .md\:w-\[70\%\] {

        width: 70%
    }

    .md\:w-auto {

        width: auto
    }

    .md\:max-w-\[32rem\] {

        max-width: 32rem
    }

    .md\:grid-cols-2 {

        grid-template-columns: repeat(2, minmax(0, 1fr))
    }

    .md\:grid-cols-3 {

        grid-template-columns: repeat(3, minmax(0, 1fr))
    }

    .md\:flex-row {

        flex-direction: row
    }

    .md\:items-center {

        align-items: center
    }

    .md\:justify-center {

        justify-content: center
    }

    .md\:gap-24 {

        gap: 6rem
    }

    .md\:gap-8 {

        gap: 2rem
    }

    .md\:gap-\[3\.2rem\] {

        gap: 3.2rem
    }

    .md\:gap-x-20 {

        -moz-column-gap: 5rem;

             column-gap: 5rem
    }

    .md\:px-24 {

        padding-left: 6rem;

        padding-right: 6rem
    }

    .md\:pb-96 {

        padding-bottom: 24rem
    }

    .md\:text-\[1\.7rem\] {

        font-size: 1.7rem
    }

    .md\:text-\[1\.8rem\] {

        font-size: 1.8rem
    }

    .md\:text-\[9\.6rem\] {

        font-size: 9.6rem
    }

    .md\:tracking-normal {

        letter-spacing: 0em
    }

    .group:hover .md\:group-hover\:bg-background-main {

        --tw-bg-opacity: 1;

        background-color: rgb(var(--palette-background-mainChannel) / var(--tw-bg-opacity, 1))
    }

    .group:hover .md\:group-hover\:shadow-md {

        --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);

        --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);

        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
    }
}

@media (min-width: 1280px) {

    .lg\:h-6 {

        height: 1.5rem
    }

    .lg\:grid-cols-3 {

        grid-template-columns: repeat(3, minmax(0, 1fr))
    }

    .lg\:grid-cols-4 {

        grid-template-columns: repeat(4, minmax(0, 1fr))
    }

    .lg\:flex-row {

        flex-direction: row
    }

    .lg\:gap-10 {

        gap: 2.5rem
    }

    .lg\:gap-12 {

        gap: 3rem
    }

    .lg\:p-2\.5 {

        padding: 0.625rem
    }

    .lg\:pb-12 {

        padding-bottom: 3rem
    }

    .lg\:pt-24 {

        padding-top: 6rem
    }

    .lg\:text-\[1\.8rem\] {

        font-size: 1.8rem
    }

    .lg\:text-xl {

        font-size: 1.25rem;

        line-height: 1.75rem
    }
}

@media (min-width: 1536px) {

    .xl\:h-7 {

        height: 1.75rem
    }

    .xl\:w-60 {

        width: 15rem
    }

    .xl\:max-w-screen-xl {

        max-width: 1536px
    }

    .xl\:text-2xl {

        font-size: 1.5rem;

        line-height: 2rem
    }
}



/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/f1df658da56627d0-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/4de1fea1a954a5b6-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/162938472036e0a8-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/6d664cce900333ee-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/af4d27004aa34222-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/7ff6869a1704182a-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: 'Lato Fallback';src: local("Arial");ascent-override: 101.03%;descent-override: 21.80%;line-gap-override: 0.00%;size-adjust: 97.69%
}.__className_1dd84e {font-family: 'Lato', 'Lato Fallback';font-style: normal
}

