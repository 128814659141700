/**
 * Component classes registered by plugins.
 */

@import "print.css";

@import "nprogress.css";

@tailwind components;

.grecaptcha-badge {
  visibility: hidden;
}
